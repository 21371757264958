<style>
.box-off {
  padding: 0 10px 8px;
  flex: 1 1;
}
.box-off th {
  width: 70px;
}

table th.numbered {
  color: #bbb;
  font-size: 23px;
  padding: 0;
}
</style>
<template>
  <div class="box-off">
    <table class="is-size-8" :class="showCopy ? 'table' : 'table-plain'">
      <tr v-if="index !== undefined">
        <th colspain="2" class="numbered">#{{index}}</th>
      </tr>
      <tr v-if="contact.Name">
        <th class="vam">Name:</th>
        <td class="vam">
          <span id="CustomerServiceName">{{ contact.Name }}</span>
        </td>
        <td class="vam" v-if="showCopy">
          <button class="button is-small" v-on:click="copyToClipboard('CustomerServiceName')">Copy</button>
        </td>
      </tr>
      <tr v-if="contact.Email">
        <th class="vam">Email:</th>
        <td class="vam">
          <span id="CustomerServiceEmail">{{ contact.Email }}</span>
        </td>
        <td class="vam" v-if="showCopy">
          <button class="button is-small" v-on:click="copyToClipboard('CustomerServiceEmail')">Copy</button>
        </td>
      </tr>
      <tr v-if="contact.Telephone">
        <th class="vam">Tel:</th>
        <td class="vam">
          <span id="CustomerServiceTelephone">{{ contact.Telephone }}</span>
        </td>
        <td class="vam" v-if="showCopy">
          <button
            class="button is-small"
            v-on:click="copyToClipboard('CustomerServiceTelephone')"
          >Copy</button>
        </td>
      </tr>
      <tr v-if="contact.Role">
        <th class="vam">Role:</th>
        <td class="vam">
          <span id="CustomerServiceRole">{{ contact.Role }}</span>
        </td>
        <td v-if="showCopy"></td>
      </tr>
    </table>
  </div>
</template>
    
<script>
import { baseMixin } from "../../../mixins/baseMixin.js";

export default {
  mixins: [baseMixin],
  props: {
    contact: {
      type: Object,
      required: true
    },
    index: {
      type: Number
    },
    showCopy: {
      type: Boolean,
      default: false,
      required: false
    }
  }
};
</script>
